@keyframes shrink {
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}

.shrink {
  animation: shrink 1s ease-in-out infinite;
}